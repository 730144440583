// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // tg_apiUrl: 'https://localhost:7278/api/',
  // apiUrl: 'https://localhost:7278/api/',
  // imgUrl: 'https://localhost:7278/',
  tg_apiUrl:'https://worklogapi.logiclync.com/api/',
  apiUrl: 'https://worklogapi.logiclync.com/api',
  imgUrl: 'https://worklogapi.logiclync.com/',
  client_id: '',
  client_secret: '',
  generic_error: 'An error occured. Please try again later.',
  pageTitle: ' Work-Log',
  phoneNumberValidatorRegex: /^\(?([0-9]{3})\)?[ -.●]?([0-9]{3})[-.●]?([0-9]{4})$/gm
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
