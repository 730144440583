import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "./api.service";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  signIn(socialAuthService: any) {
    throw new Error("Method not implemented.");
  }
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  get isLoggedIn() {
    if (localStorage.getItem("currentUser")) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

  public user: BehaviorSubject<string> = new BehaviorSubject<string>("");

  public userImage: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    var user = this.currentUserSubject.value;
    // console.log(user);

    return user;
  }

  login(body: any) {
    var bodyNew = {
      userName: body.email,
      password: body.password,
    };
    return this.http
      .post(environment.tg_apiUrl + "Account/signin", bodyNew)
      .pipe(
        map((data: any) => {
          if (data && data.token) {
            localStorage.setItem("currentUser", JSON.stringify(data));
            this.currentUserSubject.next(data);
          }
          return data;
        })
      );
  }

  //Project CRUD methods
  createProject(projectData: any) {
    return this.http.post(
      environment.tg_apiUrl + "Project/create",
      projectData
    );
  }

  getAllProject() {
    return this.http.get(environment.tg_apiUrl + "Project/getall");
  }

  deleteProject(id: number) {
    return this.http.post(
      environment.tg_apiUrl + "Project/delete?id=" + id,
      id
    );
  }

  updateProject(data) {
    return this.http.post(environment.tg_apiUrl + "Project/update", data);
  }
  //End of Project CRUD methods

  //Location CRUD methods
  createLocation(locationData: any) {
    return this.http.post(
      environment.tg_apiUrl + "Locations/create",
      locationData
    );
  }

  getLocationData() {
    return this.http.get(environment.tg_apiUrl + "Locations/getall");
  }

  updateLocation(data) {
    return this.http.post(environment.tg_apiUrl + "Locations/update", data);
  }

  deleteLocation(id: number) {
    return this.http.post(
      environment.tg_apiUrl + "Locations/delete?id=" + id,
      id
    );
  }
  //End of Location CRUD methods

  //Task Database related function
  getTask() {
    return this.http.get(environment.tg_apiUrl + "Work/getall");
  }

  createTask(body: any) {
    return this.http.post(environment.tg_apiUrl + "Work/create", body);
  }

  deleteTask(id) {
    return this.http.post(
      environment.tg_apiUrl + "Work/delete?id=" + `${id}`,
      ""
    );
  }

  updateTask(body) {
    return this.http.post(environment.tg_apiUrl + "Work/update", body);
  }
  //Task Database related Functions ends Here!!!

  // Worklog Database related function
  getWorklog() {
    return this.http.get(environment.tg_apiUrl + "WorkLog/getall");
  }

  createWorklog(body: any) {
    return this.http.post(environment.tg_apiUrl + "WorkLog/create", body);
  }

  deleteWorklog(id) {
    return this.http.post(
      environment.tg_apiUrl + "WorkLog/delete?id=" + `${id}`,
      ""
    );
  }

  updateWorklog(body) {
    return this.http.post(environment.tg_apiUrl + "WorkLog/update", body);
  }
  //Worklog Database related Functions ends Here!!!

  //User CRUD functions start here

  registerUser(userData: any) {
    return this.http.post(environment.tg_apiUrl + "User/create", userData);
  }

  getAllUser() {
    return this.http.get(environment.tg_apiUrl + "User/getall");
  }

  getUserById(id) {
    return this.http.get(environment.tg_apiUrl + "User/getbyid?id=" + id);
  }

  updateUser(userData) {
    return this.http.post(environment.tg_apiUrl + "User/update", userData);
  }

  deleteUser(id) {
    return this.http.post(
      environment.tg_apiUrl + "User/delete?id=" + `${id}`,
      ""
    );
  }

  //User CRUD functions end here

  logout() {
    this.loggedIn.next(false);
    this.user.next("");
    this.router.navigate(["/"]);
  }

  register(username, password, firstName, lastName, phoneNumber) {
    const headers = new HttpHeaders().set("Content-Type", "application/json;");
    const body = {};

    return this.http.post(environment.apiUrl + "UserDetails/CreateUser", body, {
      headers,
    });
  }

  getAccountSetting() {
    return this.http.get(environment.tg_apiUrl + "User/getall");
  }


  //Change Password
  changePassword(body: any) {
    return this.http.post(
      environment.tg_apiUrl + "Account/Change-Password",
      body
    );
  }

  //forgot password

  forgotPassword(body: any) {
    return this.http.post(environment.tg_apiUrl + "Account/forgot-password",body);
  }

  //Reset Password
  resetPassword(body: any) {
    var res= this.http.post("https://localhost:7276/api/Account/reset-password", body);
    if(!res){
      console.log("Got null value")
    }
    return res;
    
  }

  getInvitationDetail(key: string) {
    var body = {
      invitationKey: key,
    };
    return this.http.post(environment.apiUrl + "Invite/accept", body);
  }

  inviateExSpouse(body: any) {
    return this.http.post(environment.apiUrl + "Invite/exspouse", body);
  }

  signup(body: any) {
    return this.http.post(environment.apiUrl + "Home/signup", body);
  }

  exspouseSignup(body: any) {
    return this.http.post(environment.apiUrl + "Home/exspousesignup", body);
  }

  exspouseCreate(body: any) {
    return this.http.post(environment.tg_apiUrl + "Project/create", body);
  }

  validateResetPassword(body: any) {
    return this.http.post(
      environment.apiUrl + "AccountSettings/password/reset/validate",
      body
    );
  }

  

  uploadFile(body: any) {
    return this.http.post(environment.apiUrl + "File/upload", body);
  }

  downloadFile(id: string) {
    return this.http.get(environment.apiUrl + "File/download?id=" + id);
  }

  getUploadedFiles() {
    return this.http.get(environment.apiUrl + "File/useraccount");
  }

  getUserDetailById() {
    return this.http.get(environment.apiUrl + "Home/user/");
  }

  saveUserDetails(body: any) {
    return this.http.post(environment.apiUrl + "Home/save", body);
  }

  getmembers() {
    return this.http.get(environment.tg_apiUrl + "members");
  }

  private _listeners = new Subject<any>();
  listen(): Observable<any> {
    return this._listeners.asObservable();
  }
  filter(filterBy: string) {
    this._listeners.next(filterBy);
  }
}
