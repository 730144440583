<nav class="main-header navbar navbar-expand navbar-dark">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"
        ><i class="fas fa-bars"></i
      ></a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->

<!-- Main Sidebar Container -->
<aside
  class="main-sidebar sidebar-dark-dark elevation-4 position-fixed"
  id="collapseExample"
>
  <!-- Brand Logo -->
  <div class="showDesktop">
    <a [routerLink]="['/account']" class="brand-link w-75">
      <img
        src="../../../../assets/images/logiclync.png"
        alt="logiclync"
        class="brand-image w-100"
      />
      <!-- <span class="brand-text font-weight-light" style="color: #000;">QDRO Express</span> -->
    </a>
    <div class="sidebar topHeaderMenu">
      <a data-widget="pushmenu" href="#" role="button"
        ><i class="fas fa-arrow-circle-left"></i
      ></a>
    </div>
  </div>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar Menu -->
    <nav class="mt-2 change">
      <ul
        class="nav nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
        <li class="nav-item">
          <a
            class="hideDesktop text-center"
            data-widget="pushmenu"
            href="#"
            role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <ng-container *ngIf="!isMobile">
          <li class="nav-item">
            <a [routerLink]="['/account']" class="nav-link">
              <i class="nav-icon fas fa-home"></i>
              <p>Dashboard</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/account/work-logs']" class="nav-link">
              <i class="fas fa-clipboard-list pr-2 ml-2"></i>
              <p>Work Logs</p>
            </a>
          </li>
          <li class="my-2">
            <hr class="border-bottom-0" style="box-shadow: 0 0 1px 0" />
          </li>
          <li class="nav-item">
            <a
              [routerLink]="[]"
              class="nav-link"
              (click)="toggleChild()"
              [class.empdisplay]="checkAdmin() == true"
            >
              <i class="fas fa-users-cog pr-2 ml-2"></i>
              <p>
                Administrator
                <span class="badge badge-info right">4</span>
              </p>
            </a>
            <ul class="nav ml-2" id="mains">
              <li class="nav-item" style="display: none">
                <a
                  [routerLink]="['/account/users']"
                  routerLinkActive="actives"
                  class="nav-link active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <i class="fas fa-users pr-2"></i>
                  <p>Users</p>
                </a>
              </li>
              <li class="nav-item" style="display: none">
                <a
                  [routerLink]="['/account/projects']"
                  routerLinkActive="actives"
                  class="nav-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <i class="fas fa-briefcase pr-2"></i>
                  <p>Project</p>
                </a>
              </li>
              <li class="nav-item" style="display: none">
                <a
                  [routerLink]="['/account/tasks']"
                  routerLinkActive="actives"
                  class="nav-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <i class="fas fa-tasks pr-2"></i>
                  <p>Tasks</p>
                </a>
              </li>
              <li class="nav-item" style="display: none">
                <a
                  [routerLink]="['/account/locations']"
                  routerLinkActive="actives"
                  class="nav-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <i class="fas fa-map-marker-alt pr-2"></i>
                  <p>Locations</p>
                </a>
              </li>
            </ul>
          </li>

          <li class="my-2">
            <hr class="border-bottom-0" style="box-shadow: 0 0 1px 0" />
          </li>

          <li class="nav-item">
            <a [routerLink]="['/account/account-settings']" class="nav-link">
              <i class="nav-icon fas fa-cog"></i>
              <p>Account Settings</p>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#confirmLogout"
              class="nav-link"
            >
              <i class="nav-icon fa fa-power-off"></i>
              <p>Sign out</p>
            </a>
          </li>
        </ng-container>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>

<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
<!-- /.content-wrapper -->
<div
  class="modal fade"
  id="confirmLogout"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirm Sign out</h5>
      </div>
      <div class="modal-body">
        <span>Are you sure you want to Sign out ?</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          No
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-dismiss="modal"
          (click)="signOut()"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
